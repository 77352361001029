@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.App {
  justify-content: center;
  text-align: center;
  max-width: 100vw;
  height: 100vh;
  font-family: "Roboto", sans-serif;
}

:root {
  --darkgray: #151b1f;
  --darkgray1: #11161b;
  --primary: #119da4;
  --lightgray: #23292e;
  /* 60, 60, 75 */
}

.header-main-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.header-container {
  background-color: var(--darkgray1);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  transition: all 300ms ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.header-item {
  grid-row: 1;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  margin-left: 1rem;
  margin-right: auto;
}

.header-item img {
  height: 2rem;
  resize: horizontal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.burger-menu {
  grid-row: 1;
  margin-left: auto;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
}

.burger-stroke {
  height: 1px;
  width: 1.8rem;
  background-color: white;
  position: relative;
  left: 50%;
  top: 50%;
  transition: transform 800ms ease;
}

.burger-stroke-1 {
  transform: translate(-50%, calc(-50% - 0.3rem));
}

.burger-stroke-1.open {
  transform: translate(-50%, -50%) rotate(45deg);
}

.burger-stroke-2 {
  transform: translate(-50%, calc(-50% + 0.3rem));
}

.burger-stroke-2.open {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.header-menu-container {
  background-color: var(--darkgray1);
  width: calc(100% - 4rem);
  display: grid;
  /* display: none; */
  max-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  transition: max-height 300ms ease, padding-top 300ms ease,
    padding-bottom 300ms ease;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 0rem;
  overflow: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.header-menu-container .header-menu-item {
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  align-items: center;
  justify-items: center;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  transition: opacity 300ms ease 100ms;
}

.header-menu-container .header-menu-item:last-child {
  border-bottom: none;
}

.header-menu-container .header-menu-item h1 {
  color: white;
  font-weight: 400;
  font-size: larger;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  display: block;
}

.header-menu-container.close {
  max-height: 0;
  transition: max-height 300ms ease 100ms, padding-top 300ms ease 100ms,
    padding-bottom 300ms ease 100ms;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.header-menu-container.close .header-menu-item {
  opacity: 0;
  transition: opacity 300ms ease;
}

.header-menu-container-desktop {
  display: none;
  grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  justify-self: flex-end;
  margin-right: 1rem;
}

.header-menu-container-desktop .header-menu-item {
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  align-items: center;
  justify-items: center;
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  transition: opacity 300ms ease 100ms;
}

.header-menu-container-desktop .header-menu-item:last-child {
  border-bottom: none;
}

.header-menu-container-desktop .header-menu-item h1 {
  color: white;
  font-weight: 400;
  font-size: larger;
  text-align: center;
}

.header-menu-container-desktop .header-menu-item.selected h1 {
  color: var(--primary);
}

.languages-main-container-desktop {
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}

.languages-main-container-desktop .languages-item {
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
}

.languages-main-container-desktop .languages-item h4 {
  margin: 0px;
  font-size: small;
}

.languages-main-container {
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}

.languages-item {
  color: white;
  align-self: center;
  align-items: center;
  text-align: center;
}

.languages-item h4 {
  font-weight: 400;
}

.languages-item.selected {
  color: var(--primary);
}

@media screen and (min-width: 767px) {
  .header-menu-container-desktop {
    display: grid;
    /* display: none; */
  }
  .header-menu-container {
    display: none;
  }
  .burger-menu {
    display: none;
  }
  .header-container {
    grid-template-columns: auto 1fr;
  }
  .header-item img {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
  }
}
